<template>
  <!-- 实验指南 -->
  <div class="main-contain">
    <div class="itemcontain" v-loading="loading">
      <template v-if="guide">
        <div class="text" v-html="guide"></div>
      </template>
      <template v-else>
        <div class="is-empty" style="text-align: center;">
          <img :src="no_math" style="width: 70%;" />
          <div class="vr_project">
            <span>暂无实验指南</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { getVRGuide } from "@/api/vr"
export default {
  name: "Courseintroduction",
  components: {},
  data () {
    return {
      testimage: require("@/assets/test.png"),
      no_math: require('@/assets/no_math1.png'),
      guide: "",
      loading:true
    }
  },
  methods: {
    initdata (data) {
      this.getinit(data)
    },
    getinit (data) {
      this.loading = true
      getVRGuide({ id: data.id }).then(res => {
        this.guide = res.data.guide
        this.loading = false
      }).catch((error) => {
        //console.log(error)
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  padding: 10px;
  background: #ffffff;
  height: 550px;
  overflow-y:scroll;
  .itemcontain {
    margin-top: 20px;

    ::v-deep img {
      width: 100%;
      height: 100%;
    }

    .text {
      // text-indent: 2em;
      line-height: 25px;
      margin: 0 auto;
      margin-top: 10px;
      font-size: 15px;
      font-weight: 400;
      color: #666666;
    }
  }
  @media screen and (max-width: 640px) {
    .vr_project {
      font-size: 16px;
      font-weight: bold;
      color: #b5a2a2;
      margin-bottom: 30px;
    }
  }

  @media screen and (min-width: 640px) and (max-width: 1200px) {
    .vr_project {
      font-size: 16px;
      font-weight: bold;
      color: #b5a2a2;
      margin-bottom: 30px;
    }
  }

  @media screen and (min-width: 1200px) {
    .vr_project {
      font-size: 24px;
      font-weight: bold;
      color: #b5a2a2;
      margin-bottom: 30px;
    }

  }
}
</style>
