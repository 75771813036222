<template>
  <!-- 学习资料 -->
  <div class="main-contain">
    <div class="contain">
      <el-table ref="singleTable" :data="tableData" highlight-current-row style="width: 100%" class="Table" >
        <el-table-column label="文件名" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="item-left">
              <el-image class="imageicon" :src="file_icon(scope.row.ext)" fit="contain" />
              <div class="title">{{ scope.row.name }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="大小">
          <template slot-scope="scope">
            <div class="item-middle">
              <div class="title">{{ scope.row.size }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div class="item-right">
              <div class="bt" @click="lookbt(scope.row)">查看</div>
            </div>
            <div class="item-right">
              <div class="bt" @click="download" v-if="scope.row.is_private == 0">下载</div>
            </div>
          </template>
          <!-- <template slot-scope="scope">
            <div class="">
              <div class="bt" @click="download"></div>
            </div>
          </template>-->
        </el-table-column>
      </el-table>
      <div class="elpagination">
        <!-- <div class="elpagination-title">共{{ total }}条</div> -->
        <el-pagination background layout="prev, pager, next" :total="total" :page-size="10" 
          :current-page="queryParams.page" @current-change="pageChange" ></el-pagination>
      </div>
    </div>
    <Customdialog ref="customdialog" class="customdialog" width="800px" type="form" title="预览" 
      :showclose="true" @isDialogClose="close_dialog" >
      <div class="dialogbody" slot="dialogbody">
        <template v-if="type == '视频'">
          <video class="video_view" ref="vueRef" :src="videoviewurl" controls controlslist="nodownload noplaybackrate"
            disablepictureinpicture @contextmenu.prevent="handleRMouse" width="100%" height="500" />
        </template>
        <template v-if="type == '图片'">
          <iframe class="iframe_cont" :src="imageviewurl" frameborder="0" ref="myiframe"></iframe>
        </template>
        <template v-if="type == '其他'">
          <!-- <div class="tip_con">
            <div class="icon">!</div>
            <div class="tip_tex">
              <p v-show="is_private==0">该文件暂时不支持在线预览，您可以下载后查看！</p>
              <p v-show="is_private!=0">该文件暂时不支持在线预览！</p>
            </div>
          </div>-->
          <iframe class="iframe_cont" :src="file_url" frameborder="0" ref="myiframe"></iframe>
        </template>
        <template v-if="type == 'PDF'">
          <!-- <div class="pdf_con">
            <md-contract :url="fileurl" :visible="contractVisable" :showBtns="true" @handleModal="close"></md-contract>
          </div>-->
          <iframe class="iframe_cont" :src="fileurl" frameborder="0" ref="myiframe"></iframe>
        </template>
        <div id="toolbar" :class="toolbar == false ? 'tool-box doc_1' : 'tool-box'" style="visibility: visible;">
          <div class="line-bar1"></div>
          <div class="full-box">
            <a class="full-btn1" @click="screen">
              <i class="el-icon-full-screen" style="font-size:17px"></i>
              <span style="margin-left:4px">全屏</span>
            </a>
            <!-- <a class="full-btn2" @click="fullScreen" style="display:none">
              <i class="iconfont"></i><span style="margin-left:4px">退出全屏</span>
            </a>-->
          </div>
        </div>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <el-button type="primary" @click="download" v-if="!is_download">下载</el-button>
        <el-button type="info" @click="close_down">关闭</el-button>
      </div>
    </Customdialog>
  </div>
</template>
<script>
import mdContract from "@/contract.md.vue"
import Customdialog from "@/components/customdialog.vue"
import { getVRAttachments } from "@/api/vr"
import { Base64 } from 'js-base64'
import screenfull from 'screenfull'
const request_base_path = require('@/utils/base_url')
export default {
  name: "virtualexperiment",
  components: {
    Customdialog,
    mdContract,
  },
  data () {
    return {
      filePreview: "", // 数据预览
      picturesPreview: "", // 多图预览
      fileTask: "", // 数据预埋
      fileurl: "",
      file_url: "",
      type: "",
      videoviewurl: "",
      imageviewurl: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/文件类型-标准图-视频文件.png"),
      icon2: require("@/assets/coursedetails/矢量智能对象 (1).png"),
      tableData: [],
      imageviewurlList: [], // 图片数据
      isImg: ["jpg", "png", "jpeg", "gif", "webp"],
      total: 0,
      queryParams: {
        page: 1,
        per_page: 10
      },
      is_private: "",
      fileicon1: require("@/assets/fileicon/files.png"),
      fileicon2: require("@/assets/fileicon/icon-PDF.png"),
      fileicon3: require("@/assets/fileicon/icon-word.png"),
      fileicon4: require("@/assets/fileicon/icon-video.png"),
      fileicon5: require("@/assets/fileicon/icon-img.png"),
      fileicon6: require("@/assets/fileicon/icon-node.png"),
      fileicon7: require("@/assets/fileicon/icon-audio.png"),
      fileicon8: require("@/assets/fileicon/icon-chapter.png"),
      fileicon9: require("@/assets/fileicon/rar.png"),
      fileicon10: require("@/assets/fileicon/single.png"),
      fileicon11: require("@/assets/fileicon/txt.png"),
      fileicon12: require("@/assets/fileicon/xlsx.png"),
      fileicon13: require("@/assets/fileicon/zip.png"),


      is_download: 0,
      itemObj: {},

      fullscreen: false, //全屏
      toolbar: false,
    }
  },
  created() {
    const systemConfig = sessionStorage.getItem("systemConfig");
    const systemInfo = systemConfig ? JSON.parse(systemConfig) : {};
    if (systemInfo.file_preview_url) {
      this.filePreview = systemInfo.file_preview_url + "/onlinePreview?url="; // 数据预览
      this.picturesPreview = systemInfo.file_preview_url + "/picturesPreview?urls="; // 多图预览
      this.fileTask = systemInfo.file_preview_url + "/addTask?url=" // 数据预埋
    } else {
      this.filePreview = request_base_path.pro_url + "/file-preview/onlinePreview?url=" // 数据预览
      this.picturesPreview = request_base_path.pro_url + "/file-preview/picturesPreview?urls=" // 多图预览
      this.fileTask = request_base_path.pro_url + "/file-preview/addTask?url=" // 数据预埋
    }
  },
  methods: {
    handleRMouse (e) {
      e.preventDefault()
    },
    initdata (data) {
      this.queryParams.vr_id = data.id
      this.queryParams.classify = 1
      this.getVRAttachments()
    },
    file_icon (val) {
      switch (val) {
        case "file":
          return this.fileicon1

        case "pdf":
          return this.fileicon2

        case ("word", "docx"):
          return this.fileicon3

        case "mp4":
          return this.fileicon4

        case ("png", "jpg"):
          return this.fileicon5

        case "radio":
          return this.fileicon7

        case "rar":
          return this.fileicon9

        case "txt":
          return this.fileicon11

        case "xlsx":
          return this.fileicon12

        case "zip":
          return this.fileicon13

        default:
          return this.fileicon10
      }
    },
    async lookbt (item) {
      this.resetFill()
      await this.getTask(item.url)
      this.is_private = item.is_private
      if (item.ext == 'mp4' || item.ext == 'avi' || item.ext == 'flv') {
        this.type = "视频"
        this.videoviewurl = item.url
        this.toolbar = false
      } else if (this.isImg.includes(item.ext)) {
        this.type = "图片"
        let len = this.imageviewurlList.length
        for (let i = 0; i < this.imageviewurlList.length; i++) {
          if (this.imageviewurlList[i] == item.url) {
            let newArr = this.imageviewurlList.splice(i, len - i)
            let oldArr = this.imageviewurlList
            this.imageviewurlList = [...newArr, ...oldArr]
            break
          }
        }
        let url = this.imageviewurlList.join("|")
        this.imageviewurl = this.picturesPreview + encodeURIComponent(Base64.encode(url))
        this.toolbar = true
      } else if (item.ext == "pdf") {
        this.type = "PDF"
        this.fileurl = this.filePreview + encodeURIComponent(Base64.encode(item.url))
        this.toolbar = true
      } else {
        this.type = "其他"
        this.file_url = this.filePreview + encodeURIComponent(Base64.encode(item.url))
        this.toolbar = true
      }
      this.itemObj = item
      this.is_download = item.is_private
      this.$refs.customdialog.dialogopenbt()
    },
    resetFill () {
      this.imageviewurl = ''
      this.fileurl = ''
      this.file_url = ''
    },
    getTask (data) {
      this.fileTask + data
    },
    // 下载
    download () {
      let row = this.itemObj
      //1.先创建一个a标签
      let a = document.createElement("a")
      a.target = '_bank'
      a.download = row.name
      //2.给a标签的href赋上下载地址
      a.href = row.url
      //3.让a标签不显示在视图中
      a.style.display = "none"
      //4.将a标签append到文档中
      document.body.appendChild(a)
      //5.a标签自点击
      a.click()
      //6.点击下载后，销毁这个节点
      document.body.removeChild(a)
    },
    close_dialog () {
      this.resetFill()
    },
    close_down () {
      this.$refs.customdialog.dialogclosebt()
      let _vueRef = this.$refs.vueRef
      if (this.type === '视频') {
        _vueRef.pause() //暂停
      }
      setTimeout(() => {
        this.itemObj = {}
        this.is_download = 0
      }, 500)
    },

    getVRAttachments () {
      getVRAttachments(this.queryParams).then((response) => {
        this.tableData = response.data.data
        response.data.data.forEach(el => {
          if (this.isImg.includes(el.ext)) {
            this.imageviewurlList.push(el.url)
          }
        })
        this.total = response.data.total
      })
        .catch((error) => {
          //console.log(error)
        })
    },
    //页码发生改变时
    pageChange (val) {
      this.queryParams.page = val
      this.getVRAttachments()
    },
    //全屏
    screen () {
      if (screenfull.isEnabled) {
        screenfull.toggle(this.$refs.myiframe)
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  margin-top: 10px;
  position: relative;

  .contain {
    position: absolute;
    width: 100%;
    height: 100%;

    .Table {
      width: 100%;

      .item-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .imageicon {
          min-width: 25px;
          height: 25px;
        }

        .title {
          display: inline-block;
          margin-left: 4px;
          font-size: 15px;
          font-weight: 400;
          width: 200px;
          color: #222222;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
      }

      .item-middle {
        .title {
          font-size: 15px;

          font-weight: 400;
          color: #222222;
        }
      }

      .item-right {
        margin-right: 10px;

        .bt {
          font-size: 15px;
          font-weight: 400;
          color: #3d84ff;
          user-select: none;
          cursor: pointer;
        }
      }
    }
  }

  .customdialog {
    .dialogbody {
      padding: 10px;

      .video_view {
      }

      .image_view {
        height: 600px;
        width: 100%;
      }

      .pdf_con {
        position: relative;
      }

      .tip_con {
        height: 60px;
        background: #e6f7ff;
        border: 1px solid #91d5ff;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          margin-right: 10px;
          width: 20px;
          height: 20px;
          line-height: 17px;
          text-align: center;
          border-radius: 50%;
          background: #1890ff;
          color: white;
        }

        .tip_tex {
          font-size: 20px;
          font-weight: bold;
          color: #999999;
        }
      }
    }

    .dialogfooter {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  ::v-deep .cell {
    display: flex;
  }
}

.elpagination {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .elpagination-title {
    margin-right: 4px;
  }
}
</style>
