<template>
  <div class="main-contain">
    <div class="check-group">
      <el-radio-group v-model="radio" @change="changebt">
        <el-radio :label="0">全部</el-radio>
        <el-radio :label="1">仅老师参与</el-radio>
        <el-radio :label="2">回复最多</el-radio>
      </el-radio-group>
    </div>
    <div style="display:flex;justify-content: flex-end;margin-top:-25px;">
      <el-button type="primary" style="background: #3d84ff;" @click="add_interactive">新增问答</el-button>
    </div>
    <div class="item" v-for="(item, index) in qadata" :key="index">
      <div style="display:flex;align-self:center;">
        <div class="title" @click="toreply(item)">{{ item.title }}</div>
        <div class="item-two">
          <div v-if="item.is_top === 1" class="item-two-bt item-two-bt-active">置顶</div>
          <div v-if="item.is_teacher_join === 1" class="item-two-bt">老师参与</div>
          <div v-if="Number(item.has_publish) === 0" class="item-two-bt item-two-bt-active-1">待审核</div>
        </div>
      </div>

      <div class="text">
        <div v-html="item.content"></div>
      </div>
      <div class="bottom-contain">
        <div class="bottom-left">
          <el-image class="elimage" :src="item.avatar">
            <template slot="error">
              <el-image :src="avater1"></el-image>
            </template>
          </el-image>
          <div class="teach">{{ item.author_name }}</div>
          <div class="time">{{ item.created_at }}</div>
          <div class="time">发布</div>
        </div>
        <div class="bottom-right" v-if="Number(item.has_publish) === 1">
          <div class="bottom-right-item">
            <img class="elimage1 elimage-icons" :src="view_d" />
            <div class="num">{{ item.view_count }}</div>
          </div>
          <div class="line"></div>
          <div class="bottom-right-item" @click="setlikebt(item)">
            <template v-if="!item.like_status">
              <img class="elimage2 elimage-icons" :src="like_d" />
              <div class="num">{{ item.like_count }}</div>
            </template>
            <template v-else>
              <img class="elimage2 elimage-icons" :src="like_c" />
              <div class="num">{{ item.like_count }}</div>
            </template>
          </div>
          <div class="line"></div>
          <div class="bottom-right-item" @click="toreply(item)">
            <img class="elimage3 elimage-icons" :src="review_d" />
            <div class="num">{{ item.reply_count }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="elpagination">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination background layout="prev, pager, next" :total="total" :page-size="10"
        :current-page="queryParams.page" @current-change="pageChange"></el-pagination>
    </div>
    <Customdialog ref="customdialog" :type="'max'" width="60%" title="互动问答回复" :showclose="true">
      <div slot="dialogbody" class="dialogbody">
        <Interactiveanswerreply ref="replypanel" @childReset="initNewData"></Interactiveanswerreply>
      </div>
    </Customdialog>
  </div>
</template>

<script>
import { autoImgCorrection } from "@/utils/index"
import { getQaList, setQaLike } from "@/api/qa"
import { getVRInfo } from "@/api/vr"
import { getToken } from "@/utils/auth"
import dayjs from "dayjs"
import Interactiveanswerreply from "./interactiveanswerreply"
import Customdialog from "@/components/customdialog.vue"
const cityOptions = ["仅老师参与", "回复最多"]
const request_base_path = require('@/utils/base_url')
export default {
  name: "virtualexperiment",
  components: { Customdialog, Interactiveanswerreply },
  data() {
    return {
      base_url: request_base_path.pro_url,
      radio: 0,
      //keyword:关键字  belong_type:归属类型，0：全部，1：我发表的问答
      //type:类型，0：全部，1：老师参与，2：按回复数量排序（从多到少）
      //related_type:关联类型，关联类型，0：无关联，1：关联课程，2：关联虚拟仿真
      //related_id:关联ID
      queryParams: {
        keyword: "",
        belong_type: 0,
        type: 0,
        related_type: 2,
        related_id: '',
        page: 1,
        per_page: 10
      },
      total: 0,
      testimage: require("@/assets/test.png"),
      view_d: require("@/assets/查看_d.png"),
      like_c: require("@/assets/点赞_c.png"),
      like_d: require("@/assets/点赞_d.png"),
      review_d: require("@/assets/评论_d.png"),
      avater1: require("@/assets/学生.png"),
      checkAll: false,
      checkedCities: [],
      cities: cityOptions,
      isIndeterminate: true,
      qadata: [],
      like_status: '', // 点赞状态
      like_count: '', // 点赞数量
      name: '',
      token: getToken() ? true : false,
    }
  },
  inject: ["vr_id"],
  created() {
    this.queryParams.related_id = this.vr_id
    this.getqalist(this.queryParams)
    this.getvrInfo({ id: this.vr_id })
  },
  methods: {
    getvrInfo(queryParams) {
      getVRInfo(queryParams).then((res) => {
        this.name = res.data.name
      })
    },

    initNewData(data) {
      if (data) {
        this.getqalist(this.queryParams)
      }
    },
    initdata(data) {
      //console.log(data) item.content = autoImgCorrection(item.content)
    },
    async getqalist(params) {
      this.qadata = []
      await getQaList(params)
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            response.data.data.forEach((item) => {
              this.qadata.push({
                author_name: item.author_name,
                avatar: item.avatar,
                content: autoImgCorrection(item.content), //item.content,
                created_at: dayjs
                  .unix(item.created_at)
                  .format("YYYY-MM-DD HH:mm:ss"),
                id: item.id,
                is_teacher_join: item.is_teacher_join,
                is_top: item.is_top,
                like_count: item.like_count,
                like_status: item.like_status,
                related_name: item.related_name,
                related_type: item.related_type,
                reply_count: item.reply_count,
                title: item.title,
                has_publish: item.has_publish,
                view_count: item.view_count,
              })
              // this.like_status = item.like_status
              // this.like_count  = item.like_count
            })
            this.total = response.data.total
          }
        })
        .catch((error) => {
          //console.log(error)
        })
    },
    pageChange(val) {
      this.queryParams.page = val
      this.queryParams.related_id = this.vr_id
      this.getqalist(this.queryParams)
    },
    toreply(item) {
      // console.log("itemitemitemitemitem", item);
      this.$refs.customdialog.dialogopenbt()
      this.$nextTick(() => {
        this.$refs.replypanel.initdata(item)
      })
    },
    changebt(value) {
      this.queryParams.related_id = this.vr_id
      this.queryParams.type = value
      this.getqalist(this.queryParams)
    },
    setlikebt(item) {
      this.setQaLike({ id: item.id })
    },
    setQaLike(params) {
      setQaLike(params)
        .then((response) => {
          if (response.code === 0) {
            this.like_status = !this.like_status
            this.like_count = this.like_count ? this.like_count - 1 : this.like_count + 1
            this.queryParams.related_id = this.vr_id
            this.getqalist(this.queryParams)
          }
        })
        .catch((error) => {
          //console.log(error)
        })
    },
    add_interactive() {
      if (this.token) {
        // 跳转
        this.$router.push({
          path: "/home/personalcenter/interactiveqa",
          query: { qa_id: this.vr_id, name: this.name },
        })
      } else {
        this.$confirm('请登录后再发布问答, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 跳转
          this.$router.push({ path: "/login" })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消登录'
          });
        });
      }

    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  // border: 1px solid red;

  padding: 10px;
  background: #ffffff;

  .check-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    // margin-left: 80px;
    .check-all {
      margin-right: 20px;
    }
  }

  .item {
    padding-top: 20px;
    margin-top: 10px;
    min-height: 140px;
    border-bottom: 1px solid #ececec;

    .title {
      font-size: 20px;
      font-weight: 400;
      color: #222222;
      cursor: pointer;
    }

    .item-two {
      // margin-top: 6px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .item-two-bt {
        width: 60px;
        min-width: 60px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        padding: 0 2px;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 400;
        color: #409eff;
        background: #ffffff;
        border: 1px solid #409eff;
        border-radius: 4px;
        margin-top: 3px;
        margin-left: 10px;
      }

      .item-two-bt-active {
        color: #0fbf7f;
        width: 40px;
        min-width: 40px;
        border: 1px solid #0fbf7f;
      }

      .item-two-bt-active-1 {
        color: #f13218;
        width: 40px;
        min-width: 40px;
        border: 1px solid #f13218;
      }
    }

    .text {
      margin-top: 10px;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #222222;
    }

    .bottom-contain {
      margin-top: 20px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bottom-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .elimage {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .teach {
          margin-left: 10px;
          margin-right: 10px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }

        .time {
          margin-right: 10px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #a0a0a0;
        }
      }

      .bottom-right {
        padding-right: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .bottom-right-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;

          // .elimage1 {
          //   width: 22px;
          //   height: 16px;
          // }
          // .elimage2 {
          //   width: 19px;
          //   height: 21px;
          //   margin-bottom: 4px;
          // }
          // .elimage3 {
          //   width: 19px;
          //   height: 16px;
          // }
          .num {
            margin-left: 6px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #a0a0a0;
          }
        }

        .line {
          margin-left: 20px;
          margin-right: 20px;
          height: 15px;
          border-right: 2px solid #e8e8e8;
        }
      }
    }
  }

  .elimage-icons {
    width: 20px;
    height: 20px;
  }
}

.elpagination {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  padding-right: 20px;
  align-items: center;

  .elpagination-title {
    margin-right: 4px;
  }
}
</style>
